import React from "react";
import { motion, useAnimationControls } from "framer-motion";
import feedScreen from "./Asset/Mobile screens/Feed.png";
import profile from "./Asset/Mobile screens/profile.png";
import surprise from "./Asset/Mobile screens/Surprise.png";
import vibeBook from "./Asset/Mobile screens/Vibebook.png";
import Notification from "./Asset/Mobile screens/Notification.png";
import "./styles.css";

const animationDuration = 1;
const reverseAnimationDuration = 0.1;

const AllMobileScreens = () => {
  const slowControl = useAnimationControls();
  const fastControl = useAnimationControls();

  const initiateAnimation = () => {
    slowControl.start({
      height: "30vh",
    });
    fastControl.start({
      height: "35vh",
    });
  };
  const endAnimation = () => {
    slowControl.start({
      height: "25vh",
      transition: {
        duration: reverseAnimationDuration,
      },
    });
    fastControl.start({
      height: "25vh",
      transition: {
        duration: reverseAnimationDuration,
      },
    });
  };

  return (
    <div className="allMobileContainer">
      <motion.h1 animate={{ y: 50 }} transition={{ duration: 1 }}>
        Will AI replace jobs? Well, we dont know for sure but an AI can never
        replace your emotions and uniqueness.
      </motion.h1>
      <motion.div
        className="mobilCardsContainer"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "25px",
          alignItems: "flex-end",
        }}
        onViewportEnter={initiateAnimation}
        onViewportLeave={endAnimation}
      >
        <motion.div
          className="mobileCards"
          animate={slowControl}
          transition={{
            duration: animationDuration,
          }}
        >
          <img
            style={{ width: "auto", height: "50vh" }}
            src={vibeBook}
            alt=""
          />
        </motion.div>
        <motion.div
          className="mobileCards"
          animate={fastControl}
          transition={{
            duration: animationDuration,
          }}
        >
          <img
            style={{ width: "auto", height: "50vh" }}
            src={feedScreen}
            alt=""
          />
        </motion.div>
        <motion.div
          className="mobileCards"
          animate={slowControl}
          transition={{
            duration: animationDuration,
          }}
        >
          <img
            style={{ width: "auto", height: "50vh" }}
            src={surprise}
            alt=""
          />
        </motion.div>
        <motion.div
          className="mobileCards"
          animate={fastControl}
          transition={{
            duration: animationDuration,
          }}
        >
          <img
            style={{ width: "auto", height: "50vh" }}
            src={profile}
            alt=""
          />
        </motion.div>
        <motion.div
          className="mobileCards"
          animate={slowControl}
          transition={{
            duration: animationDuration,
          }}
        >
          <img
            style={{ width: "auto", height: "50vh" }}
            src={Notification}
            alt=""
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default AllMobileScreens;
