import React, { useState, useEffect } from "react"
import { motion, useAnimationControls } from "framer-motion"

import './badgeMedia.styles.scss'
import MediaDiv from "../mediaDiv/media-div.component"

const BadgesMedia = () => {

    const animationLeftCirlce = useAnimationControls()
    const animationRightCircle = useAnimationControls()
    const animationEmptyMediaDiv = useAnimationControls()

    const useMediaQuery = (query) => {
        const [matches, setMatches] = useState(false);

        useEffect(() => {
            const media = window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }
            const listener = () => {
                setMatches(media.matches);
            };
            media.addListener(listener);
            return () => media.removeListener(listener);
        }, [matches, query]);

        return matches;
    }

    const useIsLarge = () => useMediaQuery('(min-width: 1700px)');
    const useIsMedium = () => useMediaQuery('(min-width: 1300px)');

    const isLarge = useIsLarge()
    const isMedium = useIsMedium()

    async function sequenceLeftCircle() {
        await animationLeftCirlce.start(
            isLarge ? {
                scale: 0.45,
                left: '12.5%',
                transition: {
                    delay: 1,
                    duration: 0.8
                }
            } :
                isMedium ? {
                    scale: 0.6,
                    left: '12.5%',
                    transition: {
                        delay: 1,
                        duration: 0.8
                    }
                } :
                    {
                        scale: 0.6,
                        left: '12.5%',
                        transition: {
                            delay: 1,
                            duration: 0.8
                        }
                    }

        );
    }
    async function sequenceRightCircle() {
        await animationRightCircle.start(!isLarge ? {
            scale: 0.6,
            right: '12.5%',
            transition: {
                delay: 1,
                duration: 0.8
            }
        } : {
            scale: 0.45,
            right: '12.5%',
            transition: {
                delay: 1,
                duration: 0.8
            }
        }
        );
        await animationRightCircle.start({
            // background: 'transparent',
            scale: !isLarge ? 0.6 : 0.45,
        });
    }
    async function sequenceEmptyMediaDiv() {
        await animationEmptyMediaDiv.start(isLarge ? {
            top: '72%',
            opacity: 1,
            transition: {
                delay: 1,
                duration: 0.8
            }
        } : isMedium ? {
            // y: '450px',
            opacity: 1,
            top: '74%',
            transition: {
                delay: 1,
                duration: 0.8
            }
        } : {
            top: "75%",
            opacity: 1,
            transition: {
                delay: 1,
                duration: 0.8
            }
        }
        );
        await animationEmptyMediaDiv.start({
            opacity: 0,
            transition: {
                delay: 1
            }
        });
    }

    const leftInnerContainerCss = isLarge
        ? {
            width: '500px',
            height: '170px',
            left: "-250px",
            top: "0%",
            borderRadius: '100px',
        }
        : isMedium ? {
            width: '278px',
            height: '99px',
            left: "-90%",
            top: "0%",
            borderRadius: '100px',
        } : {
            width: '278px',
            height: '99px',
            left: "-150px",
            top: "0%",
            borderRadius: '100px',
        }

    const rightInnerContainerCss = isLarge
        ? {
            width: '500px',
            height: '170px',
            right: "70%",
            top: "0%",
            borderRadius: '100px',
        }
        : isMedium ? {
            width: '278px',
            height: '99px',
            right: "90%",
            top: "0%",
            borderRadius: '100px',
        }
            : {
                width: '278px',
                height: '99px',
                right: "40%",
                top: "0%",
                borderRadius: '100px',
            }

    return (
        <div className='parent-badgesMedia-container'>
            <div className="badges-media-container">

                <motion.div className="circle-container"
                    initial={{
                    }}
                    animate={{
                        y: '-2000px',
                    }}
                    transition={{
                        delay: 2.8,
                        duration: 0.8
                    }}
                >
                    <motion.div className="circle circle-three-left"
                        initial={{
                        }}
                        animate={{
                            scale: 0.45,
                            left: '22%',
                        }}
                        transition={{
                            delay: 1.2,
                            duration: 0.8
                        }}
                    />
                    <motion.div className="circle circle-two-left"
                        initial={{
                        }}
                        animate={animationLeftCirlce}
                        whileInView={sequenceLeftCircle}
                    >
                        <motion.div className="inner-container"
                            initial={{
                                top: "45%",
                                left: "50%",
                                height: '10%',
                                width: '10%',
                            }}
                            animate={leftInnerContainerCss}
                            transition={{
                                delay: 1.2,
                                duration: 0.6,
                            }}
                        >

                        </motion.div>

                    </motion.div>

                    <motion.div className="circle circle-one-left"
                        animate={{
                            scale: 0.3,
                            left: '1%',
                        }}
                        transition={{
                            delay: 1.2,
                            duration: 0.8
                        }}
                    />

                    <motion.div className="center-circle"
                        initial={{
                        }}
                    >
                        <motion.p
                            // initial={{ y: isLarge ? '-150px' : '-150px' }}
                            animate={{
                                top: "-150px",
                                opacity: 0,
                            }}

                            transition={{
                                duration: 0.8,
                                delay: 1.2,
                                ease: 'easeInOut'
                            }}

                        >
                            Badges
                        </motion.p>
                    </motion.div>

                    <motion.div className="circle circle-one-right"
                        animate={{
                            scale: 0.3,
                            right: '1%',
                        }}
                        transition={{
                            delay: 1.2,
                            duration: 0.8
                        }}
                    />

                    <motion.div className="circle circle-two-right"
                        animate={animationRightCircle}
                        whileInView={sequenceRightCircle}
                    >
                        <motion.div className="inner-container"

                            initial={{
                                top: "45%",
                                // right: "50%",
                                height: '10%',
                                width: '10%',
                            }}

                            animate={rightInnerContainerCss}

                            transition={{
                                delay: 1.2,
                                duration: 0.8
                            }}

                        />
                    </motion.div>

                    <motion.div className="circle circle-three-right"
                        animate={{
                            scale: 0.45,
                            right: '22%',
                        }}
                        transition={{
                            delay: 1.2,
                            duration: 0.8
                        }}
                    />
                </motion.div>

                <motion.div className="badge-media-content-container"
                    initial={{
                        opacity: 1
                    }}
                    animate={{
                        opacity: 0
                    }}
                    transition={{
                        delay: 1,
                        duration: 0.8
                    }}
                >
                    This feature helps your followers and vibers understand your evolution levels on Highon. Your badge will level up after critiquing the time you spent on the app, your activities, your number of followers, and your vibes

                </motion.div>

                <motion.div className="explore-more-container"
                    initial={{
                        opacity: 1,
                        width: '3.5%',
                        height: '6.5%',
                        borderRadius: '50%',
                    }}
                    animate={{ opacity: 0 }}
                    transition={{
                        delay: 1,
                        duration: 0.8
                    }}
                >
                    <motion.p className="plus">
                        +
                    </motion.p>
                </motion.div>

                <motion.div className="empty-media-parent-div"
                    initial={{
                        opacity: 0,
                        // y: "550px"
                    }}
                    whileInView={sequenceEmptyMediaDiv}
                    animate={animationEmptyMediaDiv}
                />

                <MediaDiv />

            </div>

        </div >
    )
}


export default BadgesMedia;