import React from "react";
import { motion, useAnimationControls } from "framer-motion";
import img1 from "./Asset/corousel_images/1.jpg";
import img2 from "./Asset/corousel_images/2.jpg";
import img3 from "./Asset/corousel_images/3.jpg";
import img4 from "./Asset/corousel_images/4.jpg";
import img6 from "./Asset/corousel_images/6.jpg";
import img7 from "./Asset/corousel_images/7.jpg";
import img8 from "./Asset/corousel_images/8.jpg";
import img9 from "./Asset/corousel_images/9.jpg";
import img10 from "./Asset/corousel_images/10.jpg";
import img11 from "./Asset/corousel_images/11.jpg";
import img12 from "./Asset/corousel_images/12.jpg";
import img13 from "./Asset/corousel_images/13.jpg";
import img14 from "./Asset/corousel_images/14.jpg";
import img15 from "./Asset/corousel_images/15.jpg";
import img16 from "./Asset/corousel_images/16.jpg";
import img17 from "./Asset/corousel_images/17.jpg";

import "./styles.css";

const AnimatedCorousel = () => {

  const controls = useAnimationControls();
  const controls2 = useAnimationControls();
  const controls3 = useAnimationControls();
  const controls4 = useAnimationControls();
  const controls5 = useAnimationControls();
  const controls6 = useAnimationControls();
  const controls7 = useAnimationControls();
  const controls8 = useAnimationControls();
  const controls9 = useAnimationControls();

  const startInfiniteAnimation = () => {
    controls.start({
      width: "20%",
      transition: {
        duration: 2,
        // repeat: Infinity,
        ease: "easeInOut"
      }
    })

    controls2.start({
      width: "5%",
      transition: {
        duration: 2,
        // repeat: Infinity,
        ease: "easeInOut"
      }
    })
    controls3.start({
      width: "15%",
      transition: {
        duration: 2,
        // repeat: Infinity,
        ease: "easeInOut"
      }
    })
    controls4.start({
      width: "5%",
      transition: {
        duration: 2,
        // repeat: Infinity,
        ease: "easeInOut"
      }
    })
    controls5.start({
      width: "25%",
      transition: {
        duration: 2,
        // repeat: Infinity,
        ease: "easeInOut"
      }
    })
    controls6.start({
      width: "100%",
      transition: {
        duration: 2,
        // repeat: Infinity,
        ease: "easeInOut"
      }
    })
    controls7.start({
      opacity: 0,
      transition: {
        duration: 2,
        // repeat: Infinity,
        ease: "easeInOut"
      }
    })
    controls8.start({
      width: "60%",
      transition: {
        duration: 2,
        // repeat: Infinity,
        ease: "easeInOut"
      }
    })
    controls9.start({
      x: '-50%',
      transition: {
        duration: 2,
        // repeat: Infinity,
        ease: "easeInOut"
      }
    })
  };

  const stopInfiniteAnimation = () => {
    controls.start({
      width: "10%",
      transition: {
        duration: 0.6
      }
    })
    controls2.start({
      width: "10%",
      transition: {
        duration: 0.6
      }
    })
    controls3.start({
      width: "10%",
      transition: {
        duration: 0.6
      }
    })
    controls4.start({
      width: "8%",
      transition: {
        duration: 0.6
      }
    })
    controls5.start({
      width: "20%",
      transition: {
        duration: 0.6
      }
    })
    controls6.start({
      width: "35%",
      transition: {
        duration: 0.6
      }
    })
    controls7.start({
      opacity: 1,
      transition: {
        duration: 0.6
      }
    })
    controls8.start({
      width: "95%",
      transition: {
        duration: 0.6
      }
    })
    controls9.start({
      x: "0",
      transition: {
        duration: 0.6
      }
    })
  };

  return (
    <div className='carouselWrapper'>
      <div className="corouselParentDiv primary" >
        <motion.div className="corouselContainer"
        onHoverStart={startInfiniteAnimation}
        onHoverEnd={stopInfiniteAnimation}
        >
          <motion.div className="corousel-div-1">

            <motion.div
              className="carouselCard corouselCategory1"
              key="1"
              custom={0}
            >
              <img className="carouselImg" src={img1} alt="img1" />
            </motion.div>

            <motion.div
              className="carouselCard corouselCategory2"
              animate={controls2}
              custom={1}
              key="2"
            >
              <img className="carouselImg" src={img2} alt="img1" />
            </motion.div>

            <motion.div
              className="carouselCard corousel-text corouselCategory2"
              key="3"
              custom={2}
              animate={controls}
            >
              All new photo viewing expreience
            </motion.div>

            <motion.div
              className="carouselCard corouselCategory2"
              animate={controls2}
              custom={3}
              key="4"
            >
              <img className="carouselImg" src={img15} alt="img1" custom={2} />
            </motion.div>

            <motion.div
              className="carouselCard corouselCategory2"
              key="5"
              custom={4}
            >
              <img className="carouselImg" src={img10} alt="img1" />
            </motion.div>

            <motion.div
              className="carouselCard corouselCategory1"
              custom={5}
              key="6"
            >
              <img className="carouselImg" src={img6} alt="img1" />
            </motion.div>

            <motion.div
              className="carouselCard corouselCategory2"
              key="7"
              custom={6}
              animate={controls2}
            >
              <img className="carouselImg" src={img7} alt="img1" />
            </motion.div>

            <motion.div
              className="carouselCard corouselCategory2"
              animate={controls3}
              custom={7}
              key="8"
            >
              <img className="carouselImg" src={img8} alt="img1" />
            </motion.div>

            <motion.div
              className="carouselCard corouselCategory2"
              animate={controls3}
              custom={7}
              key="8"
            >
              <img className="carouselImg" src={img3} alt="img1" />
            </motion.div>

          </motion.div>

          <motion.div className="corousel-div-2">

            <motion.div
              className="carouselCard corouselCategory3"
              key="1"
              custom={0}
            >
              <img className="carouselImg" src={img9} alt="img1" />
            </motion.div>

            <motion.div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: 'hidden', marginRight: "30px" }}
              className="corouselCategory4"
              animate={controls4}
            >
              <motion.div
                initial={{
                  width: "100%",
                  height: "55%",
                }}
                custom={1}
                key="2"
              >
                <img className="carouselImg" src={img4} alt="img1" />
              </motion.div>

              <motion.div
                key="3"
                initial={{
                  width: "100%",
                  height: "40%"
                }}
                custom={2}
              >
                <img className="carouselImg" src={img11} alt="img1" />
              </motion.div>
            </motion.div>

            <motion.div
              className="carouselCard corouselCategory5"
              animate={controls5}
              custom={3}
              key="4"
            >
              <img className="carouselImg" src={img12} alt="img1" custom={2} />
            </motion.div>

            <motion.div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
              className="corouselCategory3"
            >
              <div style={{ width: "100%", height: "55%", display: 'flex', justifyContent: 'space-between' }}
              >
                <motion.div
                  className="carouselCard"
                  initial={{ width: "35%" }}
                  animate={controls6}
                >
                  <img className="carouselImg" src={img13} alt="img1" />
                </motion.div>

                <motion.div
                  className="carouselCard corousel-text"
                  initial={{
                    width: "60%",
                  }}
                  animate={controls7}
                >
                  Experience like never before
                </motion.div>
              </div>
              <motion.div
                style={{
                  height: "40%"
                }}
                initial={{ width: "95%" }}
                animate={controls8}
              >
                <img className="carouselImg" src={img14} alt="img1" />
              </motion.div>
            </motion.div>

            <motion.div
              className="carouselCard corousel-text corouselCategory2"
              key="3"
              custom={2}
              animate={controls9}
            >
              More to discover on our app
            </motion.div>

            <motion.div
              className="carouselCard corouselCategory5"
              custom={3}
              key="4"
            >
              <img className="carouselImg" src={img16} alt="img1" custom={2} />
            </motion.div>

            <motion.div
              className="carouselCard corouselCategory2"
              custom={7}
              key="8"
            >
              <img className="carouselImg" src={img17} alt="img1" />
            </motion.div>
          </motion.div>

        </motion.div>
      </div>

    </div >
  );
};

export default AnimatedCorousel;
