import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'

import './media-div.styles.scss'

import image1 from '../../assets/4.png';
import image2 from '../../assets/1.png';
import image3 from '../../assets/2.png';
import image4 from '../../assets/3.png';


const MediaDiv = () => {

    const delay = 4.5
    const dur = 1

    const useMediaQuery = (query) => {
        const [matches, setMatches] = useState(false);

        useEffect(() => {
            const media = window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }
            const listener = () => {
                setMatches(media.matches);
            };
            media.addListener(listener);
            return () => media.removeListener(listener);
        }, [matches, query]);

        return matches;
    }

    const useIsLarge = () => useMediaQuery('(min-width: 1700px)');
    const useIsMedium = () => useMediaQuery('(min-width: 1300px)');

    const isLarge = useIsLarge()
    const isMedium = useIsMedium()

    return (
        <div className="media-parent-div">

            <motion.div className="badgeMedia-large-div"
                initial={{
                    top: '100%',
                    opacity: 0.1,
                }}
                animate={{
                    opacity: 1,
                    top: isLarge ? '-15%' : isMedium ? "-16%" : "-15%",
                }}

                transition={{
                    delay: 2.8,
                    duration: 0.5
                }}
            >
                <div className="col-1" >
                    <motion.div className='img-1-div'

                        initial={{
                            height: '63%'
                        }}

                        animate={{
                            height: '35%'
                        }}

                        transition={{
                            duration: dur,
                            delay: delay,
                            ease: "easeInOut"
                        }}
                    >
                        <motion.img src={image1} alt=""

                            initial={{
                                scale: 1,
                            }}

                            animate={{
                                scale: 10,
                            }}

                            transition={{
                                duration: dur,
                                delay: delay,
                                ease: "easeInOut"
                            }}
                        />

                        <motion.p
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}

                            transition={{
                                duration: dur,
                                delay: delay,
                                ease: "easeInOut"
                            }}
                        >
                            All new way photo viewing experience
                        </motion.p>

                    </motion.div>


                    <motion.div className='img-2-div'

                        initial={{
                            height: '35%'
                        }}

                        animate={{
                            height: '63%'
                        }}

                        transition={{
                            duration: dur,
                            delay: delay,
                            ease: "easeInOut",
                        }}
                    >
                        <motion.img src={image2} alt="" />

                    </motion.div>

                </div>



                <div className="col-2">

                    <motion.div className='img-1-div'
                        initial={{
                            height: '35%'
                        }}

                        animate={{
                            height: '63%'
                        }}

                        transition={{
                            duration: dur,
                            delay: delay,
                            ease: "easeInOut"
                        }}
                    >

                        <motion.img src={image3} alt=""
                            initial={{
                                scale: 10,
                            }}

                            animate={{
                                scale: 1,
                            }}

                            transition={{
                                duration: dur,
                                delay: delay,
                                ease: "easeInOut"
                            }}
                        />

                        <motion.div className='para-div'
                            initial={{
                                opacity: 1,
                                top: '100%'
                            }}
                            animate={{
                                top: isLarge ? '20%' : isMedium ? '15%' : '15%',
                            }}

                            transition={{
                                duration: 0.5,
                                delay: 3,
                                ease: "easeInOut"
                            }}
                        >
                            <motion.p
                                initial={{
                                    opacity: 1,
                                }}

                                animate={{
                                    opacity: 0,
                                }}

                                transition={{
                                    duration: dur,
                                    delay: delay,
                                    ease: "easeInOut"
                                }}
                            >
                                All new way photo viewing experience
                            </motion.p>
                        </motion.div>

                    </motion.div>

                    <motion.div className='img-2-div'

                        initial={{
                            height: '63%'
                        }}
                        animate={{
                            height: '35%'
                        }}
                        transition={{
                            duration: dur,
                            delay: delay,
                            ease: "easeInOut"
                        }}
                    >
                        <motion.img src={image4} alt=""

                            initial={{
                                scale: 1.3
                            }}

                            animate={{
                                scale: 1
                            }}

                            transition={{
                                duration: dur,
                                delay: delay,
                                ease: "easeInOut"
                            }}

                        />
                    </motion.div>
                </div>

            </motion.div>

        </div>
    )
}

export default MediaDiv;