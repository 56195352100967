import "./index.css";
import Question from "./faqComponent/question";
import supportPng from "../../../logosAndImages/MicrosoftTeams-image (1).png";

const Support = () => {
  const faq = [
    {
      question: "How to download Highon?",
      answer:
        "Highon is the world’s first Social Vibing platform available for free on App Store and Play Store. You can download the application on your Mobile directly by clicking the App Store or Play Store button on Highon Home Page. Happy Vibing!",
    },
    {
      question: "How to create Highon Account?",
      answer:
        "You can sign up to Highon by simply using the mobile number. If you are unable to login due to any technical issues, kindly reach us at support@highon.co.in.",
    },
    {
      question: "How can I log out?",
      answer:
        "If you want to log out, just go to the “App Settings” tab and click “Log out”. By logging out, you won't receive notifications from Highon. Please note that your profile will still be visible to other users.",
    },
    {
      question: "How to know what’s new on Highon?",
      answer:
        "Make sure you always have the updated version of Highon on your device. Also follow us on Instagram, Facebook and LinkedIn to know more about new features and updates",
    },
  ];
  return (
    <div className="supportPage">
      <div className="supportHeader">
        <div className="supportText">
          <h2>Customer Support 24/7</h2>
          <span>Have any doubts or concerns? We got you covered!</span>
          <p>Here’s what you can do:</p>
          <p>
            <nbsp />- Find query in FAQ section
          </p>
          <p>
            <nbsp />- Write to us at support@highon.co.in
          </p>
          <p>Hit us up with any doubts or concerns. Happy vibing!</p>
        </div>
        <div className="vectorContainer">
          <img src={supportPng} alt="faqpng" />
        </div>
      </div>
      <div className="faqContainer" id="faqs">
        <h2>Frequently Asked Questions</h2>
        <div className="dummyLine"></div>
        <div className="questionsContainer">
          {faq.map((item) => {
            return <Question item={item} />;
          })}
        </div>
      </div>
      <div className="supportTextDuplicate">
        <h2>Customer Support 24/7</h2>
        <span>Have any doubts or concerns? We got you covered!</span>
        <p>Here’s what you can do:</p>
        <p>- Find query in FAQ section</p>
        <p>- Write to us at support@highon.co.in</p>
        <p>Hit us up with any doubts or concerns. Happy vibing!</p>
      </div>
    </div>
  );
};

export default Support;
