import "./TextAnimation.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useInView } from "react-intersection-observer";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

const options = {
  threshold: 1,
};

const TextAnimation = () => {
  const animationConatinerRef = useRef(null);
  const [animationDirection, setAnimationDirection] = useState("down");
  const { ref: ref1, inView: visible1 } = useInView(options);
  const { ref: ref2, inView: visible2 } = useInView(options);
  const { ref: ref3, inView: visible3 } = useInView(options);
  const [animation1, setAnimation1] = useState("");
  const [animation2, setAnimation2] = useState("");
  const [animation3, setAnimation3] = useState("");
  const infoRef = useRef(null);
  const cloudBottom = useRef();
  const cloudTop = useRef();

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context(() => {
      gsap.to(".appInfo", {
        scrollTrigger: {
          trigger: ".appInfo",
          start: "top top",
          end: "bottom bottom",
          markers: false,
          onUpdate: (self) => {
            animateText(self.progress * 10);
          },
        },
      });
    }, animationConatinerRef);
    return () => ctx.revert();
  }, []);

  // for sequencing text animation using intersection observer
  useEffect(() => {
    let previousScrollY = 0;

    function handleScroll() {
      if (visible2) {
        if (window.scrollY > previousScrollY) {
          setAnimation1(exit);
          setAnimation2(entry);
          setAnimationDirection("down");
        } else {
          setAnimation1(entryDown);
          setAnimation2(exitDown);
          setAnimationDirection("up");
        }

        previousScrollY = window.scrollY;
      }
      if (visible3) {
        if (window.scrollY > previousScrollY) {
          setAnimation2(exit);
          setAnimation3(entry);
          setAnimationDirection("down");
          cloudTop.current.style.display = "none";
          cloudBottom.current.style.display = "none";
        } else {
          setAnimation2(entryDown);
          setAnimation3(exitDown);
          setAnimationDirection("up");
          cloudTop.current.style.display = "block";
          cloudBottom.current.style.display = "block";
        }

        previousScrollY = window.scrollY;
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visible1, visible2, visible3]);

  // different animate object for different text animation
  const exit = {
    y: ["0vh", "-50vh"],
    opacity: [1, 0],
    transition: { duration: 0.6, ease: "easeInOut" },
  };
  const entry = {
    y: ["50vh", "0vh"],

    opacity: [0, 1],
    transition: { duration: 0.6, ease: "easeInOut" },
  };
  const entryDown = {
    y: ["-50vh", "0vh"],
    opacity: [0, 1],
    transition: { duration: 0.6, ease: "easeInOut" },
  };
  const exitDown = {
    y: ["0vh", "50vh"],
    opacity: [1, 0],
    transition: { duration: 0.6, ease: "easeInOut" },
  };

  // fixing slider position for text animation
  const animateText = (scroller) => {
    if (scroller <= 0) {
      infoRef.current.style.position = "absolute";
      infoRef.current.style.top = 0;
      infoRef.current.style.bottom = "";
    }
    if (scroller > 0 && scroller < 10) {
      infoRef.current.style.position = "fixed";
    }
    if (scroller >= 10) {
      infoRef.current.style.position = "absolute";
      infoRef.current.style.bottom = 0;
      infoRef.current.style.top = "";
    }
  };

  return (
    <div ref={animationConatinerRef}>
      <div className="appInfo">
        <div className="slider" ref={infoRef}>
          <div className="cloudTop" ref={cloudTop}></div>
          <motion.div className="windowOne" animate={animation1}>
            <div className="panel1">
              <div>
                <span className="highlightText">Socializing</span> has never
                been easier.
              </div>
              <div>Meet people who truly appreciate you.</div>
              <div>
                Discover <span className="highlightText">funky avatars.</span>
              </div>
              <div>Let your heart out.</div>
              <div>
                Build a reliable tribe to{" "}
                <span className="highlightText">vibe</span> with.
              </div>
            </div>
          </motion.div>
          <motion.div className="window" animate={animation2}>
            <div className="panel2">
              <div>
                An innovative platform to{" "}
                <span className="highlightText">express yourself</span> and be
                you.
              </div>
              <div>A community that understands you.</div>
              <div>
                A <span className="highlightText">haven of trusted vibers</span>{" "}
                from all over the planet.
              </div>
            </div>
          </motion.div>
          <motion.div className="window" animate={animation3}>
            <div className="panel3">
              <div>Download the app and explore your </div>
              <div className="vibeContainer">
                <div className="vibeLayer"></div>
                <div className="highlightText">Vibe</div>
              </div>
            </div>
          </motion.div>
          <div className="cloudBottom" ref={cloudBottom}></div>
        </div>
        <div className="observerLayer">
          <div ref={ref1} className={`${animationDirection}`}></div>
        </div>
        <div className="observerLayer">
          <div ref={ref2} className={`${animationDirection}`}></div>
        </div>
        <div className="observerLayer">
          <div ref={ref3} className={`${animationDirection}`}></div>
        </div>
      </div>
    </div>
  );
};

export default TextAnimation;
