import React from "react";
import { motion, useAnimationControls } from "framer-motion";
import "./styles.css";
import Zinku1 from "./Zinku-4.png";
import Zinku2 from "./2.png";
import Zinku3 from "./3.png";
import Zinku4 from "./4.png";
import Zinku5 from "./5.png";
import Zinku6 from "./Zinku-3.png";
import Zinku7 from "./Zinku-5.png";
import ZinkuRadar from "./Radar.png";

const ZinkuSection = () => {
  const controls = useAnimationControls();
  const controls2 = useAnimationControls();
  const controls3 = useAnimationControls();
  const controls4 = useAnimationControls();
  const controls5 = useAnimationControls();
  const controls6 = useAnimationControls();
  const controls7 = useAnimationControls();
  const controls8 = useAnimationControls();
  const controls9 = useAnimationControls();
  const controls10 = useAnimationControls();

  const startMyAnimation = (sequence) => {
    switch (sequence) {
      case 0:
        controls.start({
          opacity: 1,
        });
        return;
      case 1:
        controls2.start({
          opacity: 1,
        });
        return;
      case 2:
        controls3.start({
          opacity: 1,
        });
        return;
      case 3:
        controls4.start({
          opacity: 1,
        });
        return;
      case 4:
        controls5.start({
          opacity: 1,
        });
        return;
      case 5:
        controls6.start({
          opacity: 1,
        });
        return;
      case 6:
        controls7.start({
          opacity: 1,
          scaleX: 60,
          scaleY: 160,
          borderRadius: "20px",
        });
        return;
      case 7:
        controls8.start({
          opacity: 1,
          scale: [1, 1, 0.36, 0.36, 0],
          rotateZ: [0, 0, 0, 0, 360],
          transitionEnd: { display: "none" },
        });
        return;
      case 8:
        controls9.start({
          opacity: [0, 0.3, 0.5, 0.7, 1],
          x: ["0vw", "-35vw"],
          y: ["0vh", "35vh"],
          rotateZ: [0, 360],
          scale: [1, 1.3],
        });
        return;
      case 9:
        controls10.start({
          opacity: 1,
        });
        return;
      default:
        controls.start({
          opacity: 1,
        });
        return;
    }
  };

  return (
    <motion.div
      className="zinkuSectionContainer"
      onViewportEnter={() => startMyAnimation(0)}
    >
      <motion.div
        className="zinkuItem"
        initial={{
          opacity: 0,
        }}
        animate={controls}
        transition={{
          type: "tween",
          ease: "easeInOut",
          duration: 1,
        }}
        onAnimationComplete={() => startMyAnimation(1)}
      >
        <img src={Zinku1} alt="zinku" />
      </motion.div>

      <motion.div
        className="zinkuItem"
        initial={{
          opacity: 0,
        }}
        animate={controls2}
        transition={{
          type: "tween",
          ease: "easeInOut",
          duration: 1,
        }}
        onAnimationComplete={() => startMyAnimation(2)}
      >
        <img src={Zinku2} alt="zinku" />
      </motion.div>

      <motion.div
        className="zinkuItem"
        initial={{
          opacity: 0,
        }}
        animate={controls3}
        transition={{
          type: "tween",
          ease: "easeInOut",
          duration: 1,
        }}
        onAnimationComplete={() => startMyAnimation(3)}
      >
        <img src={Zinku3} alt="zinku" />
      </motion.div>

      <motion.div
        className="zinkuItem"
        initial={{
          opacity: 0,
        }}
        animate={controls4}
        transition={{
          type: "tween",
          ease: "easeInOut",
          duration: 1,
        }}
        onAnimationComplete={() => startMyAnimation(4)}
      >
        <img src={Zinku4} alt="zinku" />
      </motion.div>

      <motion.div
        className="zinkuItem"
        initial={{
          opacity: 0,
        }}
        animate={controls5}
        transition={{
          type: "tween",
          ease: "easeInOut",
          duration: 1,
        }}
        onAnimationComplete={() => startMyAnimation(5)}
      >
        <img src={Zinku5} alt="zinku" />
      </motion.div>

      <motion.div
        className="zinkuItem"
        initial={{
          opacity: 0,
        }}
        animate={controls6}
        transition={{
          type: "tween",
          ease: "easeInOut",
          duration: 1,
        }}
        onAnimationComplete={() => startMyAnimation(6)}
      >
        <img src={Zinku6} alt="zinku" />
      </motion.div>
      <motion.div
        className="zinkuBackground"
        initial={{
          opacity: 0,
        }}
        animate={controls7}
        transition={{
          type: "tween",
          ease: "easeInOut",
          duration: 1.3,
        }}
        onAnimationComplete={() => startMyAnimation(7)}
      />

      <motion.div
        className="zinkuItem"
        style={{ zIndex: 5 }}
        initial={{
          opacity: 0,
        }}
        animate={controls8}
        transition={{
          type: "spring",
          ease: "easeInOut",
          duration: 2,
        }}
        onAnimationComplete={() => startMyAnimation(8)}
      >
        <img src={Zinku7} alt="zinku" />
      </motion.div>

      <motion.div
        className="zinkuRadar"
        initial={{
          opacity: 0,
        }}
        animate={controls9}
        transition={{
          type: "tween",
          ease: "easeInOut",
          duration: 1.5,
        }}
        onAnimationComplete={() => startMyAnimation(9)}
      >
        <img width={1000} height={1000} src={ZinkuRadar} alt="zinku" />
      </motion.div>
      <motion.span
        className="zinkuText"
        initial={{
          opacity: 0,
        }}
        animate={controls10}
        transition={{
          type: "tween",
          duration: 1.5,
        }}
      >
        Infinity is a “Secret spot” to chill with strangers anonymously. Here,
        you can safely and virtually meet new people on your bummer days.
      </motion.span>
    </motion.div>
  );
};

export default ZinkuSection;
