const frameSet = () => {
  let temp = [];
  for (let i = 1; i <= 66; i++) {
    let num = 0;
    if (i <= 9) {
      num = `00${i}`;
    } else if (i > 9 && i <= 99) {
      num = `0${i}`;
    } else {
      num = i;
    }
    const img = `./frames/ezgif-frame-${num}.jpg`;

    temp[i - 1] = `${img}`;
    // temp.push(num);
  }

  return temp;
};
export default frameSet;
