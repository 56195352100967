// import "./index.css";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import frameSet from "./sequencer";
import { motion } from "framer-motion";

import { useInView } from "react-intersection-observer";
import Vibe from "../../../vibe/vibe.component";
import Badges from "../../../badges/badge.component";
import BadgesMedia from "../../../badgeMedia/badgeMedia.component";
import EarthMedia from "../../../earthMedia/earthMedia.componet";
import EarthDiv from "../../../earthDiv/earthDiv.component";

const options = {
  threshold: 1,
};

const TextSlides = () => {
  const [animationDirection, setAnimationDirection] = useState("down");
  const { ref: ref1, inView: visible1 } = useInView(options);
  const { ref: ref2, inView: visible2 } = useInView(options);
  const { ref: ref3, inView: visible3 } = useInView(options);
  const [animation1, setAnimation1] = useState("");
  const [animation2, setAnimation2] = useState("");
  const [animation3, setAnimation3] = useState("");
  const homeContainer = useRef(null);
  const sequenceRef = useRef();
  const dummyRef = useRef();
  const infoRef = useRef();
  const vibeRef = useRef();
  const cloudBottom = useRef();
  const cloudTop = useRef();
  const [allFrames] = useState(frameSet());
  const [currentFrame, setCurrentFrame] = useState("");
  const [boolean1, setBoolean1] = useState(false);
  const [boolean3, setBoolean3] = useState(false);
  const [width, setWidth] = useState("");

  const [isScrollVibe, setIsScrollVibe] = useState(false);
  const [isScrollBadges, setIsScrollBadges] = useState(false);
  const [isScrollBadgesMedia, setIsScrollBadgesMedia] = useState(false);
  const [isScrollEarthMedia, setIsScrollEarthMedia] = useState(false);

  const [vibeLeftPosition, setVibeLeftPosition] = useState("0px");
  const [vibeBorderRadius, setVibeBorderRadius] = useState("0px");
  const [vibeBgColor, setVibeBgColor] = useState("rgba(237, 117, 47, 0)");
  const [vibeColor, setVibeColor] = useState("rgba(237, 117, 47, 1))");
  const [vibeHeight, setVibeHeight] = useState("65px");
  const [vibeWidth, setVibeWidth] = useState("100px");

  const [scroll, setScroll] = useState(0);
  const vibeDiv = document.querySelector(".vibeText");

  const getScrollPercent = () => {
    var h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight";

    var scrollPercent = Math.round(
      ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
    );

    setScroll(isNaN(scrollPercent) ? "" : scrollPercent);
  };

  const setComponents = (scroll) => {
    if (50 <= scroll && scroll <= 60) {
      setIsScrollVibe(false);
      setIsScrollBadges(false);
      setIsScrollBadgesMedia(false);
      setIsScrollEarthMedia(false);
    } else if (61 <= scroll && scroll <= 70) {
      setIsScrollVibe(true);
      setIsScrollBadges(false);
      setIsScrollBadgesMedia(false);
      setIsScrollEarthMedia(false);
    } else if (71 <= scroll && scroll <= 80) {
      setIsScrollVibe(true);
      setIsScrollBadges(true);
      setIsScrollBadgesMedia(false);
      setIsScrollEarthMedia(false);
    } else if (81 <= scroll && scroll <= 90) {
      setIsScrollVibe(true);
      setIsScrollBadges(true);
      setIsScrollBadgesMedia(true);
      setIsScrollEarthMedia(false);
    } else if (91 <= scroll && scroll <= 100) {
      setIsScrollVibe(true);
      setIsScrollBadges(true);
      setIsScrollBadgesMedia(true);
      setIsScrollEarthMedia(true);
    }
  };

  const vibeStyle = {
    right: vibeLeftPosition,
    borderRadius: vibeBorderRadius,
    backgroundColor: vibeBgColor,
    color: vibeColor,
    height: vibeHeight,
    width: vibeWidth,
  };

  function opacityConverter(color) {
    if (color.substring(0, 4) === "rgba") {
      var alpha = color.split(",");
      alpha = alpha[alpha.length - 1].trim();
      alpha = alpha.substring(0, alpha.indexOf(")"));
      return alpha;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", getScrollPercent);

    return () => {
      window.removeEventListener("scroll", getScrollPercent);
    };
  }, []);

  useEffect(() => {
    let prevScroll = 0;
    let captureScroll = 0;
    function direction() {
      if (
        prevScroll < window.scrollY &&
        scroll >= 37 &&
        scroll <= 53 &&
        captureScroll !== scroll
      ) {
        console.log("scroll down", scroll);
        var radius = vibeDiv.style.borderRadius;
        radius = parseInt(radius.slice(0, 2)) + 4;
        radius = radius.toString() + "px";

        var height = vibeDiv.style.height;
        height = parseInt(height.slice(0, height.length - 2)) + 3;
        height = height.toString() + "px";

        var width = vibeDiv.style.width;
        width = parseInt(width.slice(0, width.length - 2)) + 13;
        width = width.toString() + "px";

        var right = vibeDiv.style.right;
        right = parseInt(right.slice(0, right.length - 2)) + 6;
        right = right.toString() + "px";

        var o = vibeDiv.style.backgroundColor;
        o = opacityConverter(o);
        o = parseFloat(o) + 0.1;

        setVibeBorderRadius(radius);
        setVibeHeight(height);
        setVibeWidth(width);
        setVibeLeftPosition(right);
        setVibeBgColor(`rgba(237, 117, 47, ${o})`);
        setVibeColor(`#ffffff`);
      } else if (
        prevScroll > window.scrollY &&
        scroll >= 37 &&
        scroll <= 53 &&
        captureScroll !== scroll
      ) {
        console.log("scroll up");
        var radius1 = vibeDiv.style.borderRadius;
        radius1 = parseInt(radius1.slice(0, 2)) - 4;
        radius1 = radius1.toString() + "px";

        var height1 = vibeDiv.style.height;
        height1 = parseInt(height1.slice(0, height1.length - 2)) - 3;
        height1 = height1.toString() + "px";

        var width1 = vibeDiv.style.width;
        width1 = parseInt(width1.slice(0, width1.length - 2)) - 13;
        width1 = width1.toString() + "px";

        var right1 = vibeDiv.style.right;
        right1 = parseInt(right1.slice(0, right1.length - 2)) - 6;
        right1 = right1.toString() + "px";

        var o1 = vibeDiv.style.backgroundColor;
        o1 = opacityConverter(o1);
        o1 = parseFloat(o1) - 0.1;

        setVibeBorderRadius(radius1);
        setVibeHeight(height1);
        setVibeWidth(width1);
        setVibeLeftPosition(right1);
        setVibeBgColor(`rgba(237, 117, 47, ${o1})`);
        setVibeColor(`#ed752f`);
      } else if (captureScroll !== scroll) {
        setVibeBorderRadius("0px");
        setVibeHeight("60px");
        setVibeWidth("100px");
        setVibeLeftPosition("0px");
        setVibeBgColor(`rgba(237, 117, 47, 0)`);
        setVibeColor(`#ed752f`);
      }
      prevScroll = window.scrollY;
      captureScroll = scroll;
    }

    window.addEventListener("scroll", direction);

    return () => {
      window.removeEventListener("scroll", direction);
    };
  }, [scroll]);

  useEffect(() => {
    setComponents(scroll);
  }, [scroll]);

  // for tracking scroll position of image sequence and text animation
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context(() => {
      // for scroll video playing
      gsap.to(".videoContainer", {
        scrollTrigger: {
          trigger: ".videoContainer",
          start: "top 2%",
          end: "+=1369 bottom",
          markers: false,
          onUpdate: (self) => {
            switchVidClass(self.progress * 64);
            handleFrameChange(Math.floor(self.progress * 64));
          },
        },
      });
      gsap.to(".appInfo", {
        scrollTrigger: {
          trigger: ".appInfo",
          start: "top top",
          end: "bottom bottom",
          markers: false,
          onUpdate: (self) => {
            animateText(self.progress * 10);
          },
        },
      });
    }, homeContainer);
    return () => ctx.revert();
  }, []);

  // for centering vibe div
  useEffect(() => {
    setWidth(
      vibeRef.current.offsetLeft -
        window.innerWidth / 2 +
        vibeRef.current.offsetWidth / 1.2
    );
  }, [window.innerWidth]);

  // for sequencing text animation using intersection observer
  useEffect(() => {
    let previousScrollY = 0;

    function handleScroll() {
      if (visible1) {
        setBoolean1(true);
      }
      if (visible2) {
        if (window.scrollY > previousScrollY) {
          setBoolean1(false);
          setAnimation1(exit);
          setAnimation2(entry);
          setAnimationDirection("down");
        } else {
          setAnimation1(entryDown);
          setAnimation2(exitDown);
          setAnimationDirection("up");
          setBoolean1(true);
        }

        previousScrollY = window.scrollY;
      }
      if (visible3) {
        if (window.scrollY > previousScrollY) {
          setAnimation2(exit);
          setAnimation3(entry);
          setAnimationDirection("down");
          setBoolean3(true);
          cloudTop.current.style.display = "none";
          cloudBottom.current.style.display = "none";
        } else {
          setAnimation2(entryDown);
          setAnimation3(exitDown);
          setAnimationDirection("up");
          setBoolean3(false);
          cloudTop.current.style.display = "block";
          cloudBottom.current.style.display = "block";
          setBoolean1(false);
        }

        previousScrollY = window.scrollY;
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visible1, visible2, visible3]);

  // different animate object for different text animation
  const exit = {
    y: ["0vh", "-50vh"],
    opacity: [1, 0],
    transition: { duration: 0.6, ease: "easeInOut" },
  };
  const entry = {
    y: ["50vh", "0vh"],

    opacity: [0, 1],
    transition: { duration: 0.6, ease: "easeInOut" },
  };
  const entryDown = {
    y: ["-50vh", "0vh"],
    opacity: [0, 1],
    transition: { duration: 0.6, ease: "easeInOut" },
  };
  const exitDown = {
    y: ["0vh", "50vh"],
    opacity: [1, 0],
    transition: { duration: 0.6, ease: "easeInOut" },
  };

  // for playing image sequence
  const handleFrameChange = (scrollPos) => {
    setCurrentFrame(allFrames[scrollPos]);
  };

  // fixing slider position for text animation
  const animateText = (scroller) => {
    if (scroller < 0) {
      setBoolean1(false);
    }
    if (scroller > 0) {
      infoRef.current.style.position = "fixed";
    }
  };

  // for transition of image screen to next screen
  const switchVidClass = (zValue) => {
    if (zValue >= 64) {
      sequenceRef.current.style.zIndex = -1;
      dummyRef.current.style.height = "100vh";
    } else if (zValue < 64) {
      sequenceRef.current.style.zIndex = 2;
      dummyRef.current.style.height = "200vh";
    }
  };

  return (
    <div className="homePage" ref={homeContainer}>
      {/* <div className="videoContainer" ref={sequenceRef}>
        {currentFrame ? (
          <img src={require(`${currentFrame}`)} alt="sample" />
        ) : (
          <img src={sample} alt="sampleImage" />
        )}
      </div> */}
      <div className="dummyElement" ref={dummyRef} />
      <div className="appInfo">
        <div className="slider" ref={infoRef}>
          <div className="cloudTop" ref={cloudTop}></div>
          <motion.div
            className="window"
            animate={animation1}
            whileInView={
              boolean1
                ? {
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 0.6,
                      ease: "easeInOut",
                    },
                  }
                : ""
            }
          >
            <div className="panel1">
              <div>
                <span className="highlightText">Socializing</span> has never
                been easier.
              </div>
              <div>Meet people who truly appreciate you.</div>
              <div>
                Discover <span className="highlightText">funky avatars.</span>
              </div>
              <div>Let your heart out.</div>
              <div>
                Build a reliable tribe to{" "}
                <span className="highlightText">vibe</span> with.
              </div>
            </div>
          </motion.div>
          <motion.div className="window" animate={animation2}>
            <div className="panel2">
              <div>
                An innovative platform to{" "}
                <span className="highlightText">express yourself</span> and be
                you.
              </div>
              <div>A community that understands you.</div>
              <div>
                A <span className="highlightText">haven of trusted vibers</span>{" "}
                from all over the planet.
              </div>
            </div>
          </motion.div>

          <motion.div className="window" animate={animation3}>
            <motion.div
              className="panel3"
              whileInView={
                boolean3
                  ? {
                      transition: {
                        duration: 3,
                        times: [0, 0.3, 0.4, 0.7, 1],
                        delay: 1,
                        ease: "linear",
                      },
                    }
                  : ""
              }
            >
              <motion.div
                whileInView={
                  boolean3
                    ? {
                        x: [0, -1500, -1500, -1500, -1500],
                        transition: {
                          duration: 3,
                          ease: "linear",
                          times: [0, 0.3, 0.4, 0.7, 1],
                          delay: 1,
                        },
                      }
                    : ""
                }
                viewport={{ once: true }}
              >
                Download the app and explore your{" "}
              </motion.div>
              <motion.div
                className="vibeText"
                style={vibeStyle}
                ref={vibeRef}
                whileInView={
                  boolean3
                    ? {
                        x: [0, -width],
                        transition: {
                          duration: 0.1,
                          ease: "linear",
                          times: [0, 0.3, 0.4, 0.7, 1],
                          delay: 1,
                        },
                      }
                    : ""
                }
                viewport={{ once: true }}
              >
                Vibe
              </motion.div>
            </motion.div>
          </motion.div>

          <div className="cloudBottom" ref={cloudBottom}></div>
        </div>
        <div className="observerLayer">
          <div ref={ref1} className={`${animationDirection}`}></div>
        </div>
        <div className="observerLayer">
          <div ref={ref2} className={`${animationDirection}`}></div>
        </div>
        <div className="observerLayer">
          <div ref={ref3} className={`${animationDirection}`}></div>
        </div>
      </div>
      <div style={{ height: "450vh" }} id="animation">
        <div
          style={{
            position: "sticky",
            height: "100vh",
            top: 0,
            width: "100",
            backgroundColor: "",
          }}
        >
          {!isScrollVibe ? (
            <Vibe />
          ) : !isScrollBadges ? (
            <Badges />
          ) : !isScrollBadgesMedia ? (
            <BadgesMedia />
          ) : !isScrollEarthMedia ? (
            <EarthMedia />
          ) : (
            <EarthDiv />
          )}
        </div>
      </div>
    </div>
  );
};

export default TextSlides;
