import React from "react";
import spotlightImage from "./Path.svg";
import { motion, useAnimationControls } from "framer-motion";
import logImage from "./whiteLogo.png";

const TopSection = () => {
  const spotlightControls = useAnimationControls();
  const backgroundControls = useAnimationControls();

  const initiateAnimation = () => {
    spotlightControls.start({
      top: 0,
      left: 0,
      scale: 4.4,
      opacity: 1,
    });
    backgroundControls.start({
      backgroundColor: "black",
    });
  };
  const endAnimation = () => {
    spotlightControls.start({
      top: "-100vh",
      left: 0,
      scale: 1,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    });
    backgroundControls.start({
      backgroundColor: "black",
    });
  };
  return (
    <motion.div
      className="topSectionContainer"
      initial={{ backgroundColor: "black" }}
      animate={backgroundControls}
    >
      <motion.img
        className="spotlightImg"
        initial={{ top: "-80vh", left: "-80vw" }}
        animate={spotlightControls}
        transition={{
          duration: 1,
        }}
        src={spotlightImage}
        alt=""
      />
      <div className="logoHeaderWrapper">
        <motion.img
          className="logImage"
          style={{
            marginTop: "-10vh",
            width: "auto",
            position: "relative",
            zIndex: 6,
          }}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1.2, opacity: 1 }}
          transition={{
            duration: 3,
          }}
          src={logImage}
          alt=""
          onViewportEnter={initiateAnimation}
          onViewportLeave={endAnimation}
        />
        <motion.h1
          className="topSectionTitle"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1.2, opacity: 1, y: 35 }}
          transition={{
            duration: 3,
          }}
        >
          World's First SOCIAL VIBING Platform
        </motion.h1>
      </div>
      <div className="blurGlass" />
    </motion.div>
  );
};

export default TopSection;
