import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./component/websiteLayout/Layout";
import About from "./component/websiteLayout/pages/About";
import Career from "./component/websiteLayout/pages/Career";
import Download from "./component/websiteLayout/pages/Download";
import Support from "./component/websiteLayout/pages/Support";
import Home from "./component/websiteLayout/pages/Home";
import NoPage from "./component/websiteLayout/pages/Nopage";
import Vibe from "./component/vibe/vibe.component";
import HomePageNew from "./component/websiteLayout/pages/Home/HomePageNew";
import TermsAndCondition from "./component/helpForApp/TermsAndCondition"

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePageNew />} />
            <Route eaxct path="aboutus" element={<About />} />
            <Route exact path="support" element={<Support />} />
            <Route exact path="download" element={<Download />} />
            <Route path="career" element={<Career />} />
            <Route path="vibe" element={<Vibe />} />
            <Route path="*" element={<NoPage />} />
            <Route path="terms" element={<TermsAndCondition/>}/>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
