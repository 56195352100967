import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"

import './earthMedia.styles.scss'

import image1 from '../../assets/4.png'
import image2 from '../../assets/1.png'
import image3 from '../../assets/2.png'
import image4 from '../../assets/3.png'


const EarthMedia = () => {

    const delay = 0.5
    const dur = 1

    const useMediaQuery = (query) => {
        const [matches, setMatches] = useState(false);

        useEffect(() => {
            const media = window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }
            const listener = () => {
                setMatches(media.matches);
            };
            media.addListener(listener);
            return () => media.removeListener(listener);
        }, [matches, query]);

        return matches;
    }

    const useIsLarge = () => useMediaQuery('(min-width: 1700px)');
    const useIsMedium2 = () => useMediaQuery('(min-width: 1300px)');
    const useIsMedium1 = () => useMediaQuery('(min-width: 1000px)');
    const useIsSmall = () => useMediaQuery('(min-width: 700px)');

    const isLarge = useIsLarge()
    const isMedium2 = useIsMedium2()
    const isMedium1 = useIsMedium1()
    const isSmall = useIsSmall()


    const earthDivCss = isLarge
        ? {
            left: '-12%',
            top: '27%',
            borderRadius: '50%',
            backgroundColor: "#8DDCFF",
            width: '925px',
            height: "925px",
        } :
        isMedium2 ? {
            left: '-12%',
            top: '27%',
            borderRadius: '50%',
            backgroundColor: "#8DDCFF",
            width: '725px',
            height: "725px",
        }
            : isMedium1 ? {
                left: '-12%',
                top: '30%',
                borderRadius: '50%',
                backgroundColor: "#8DDCFF",
                width: '625px',
                height: "625px",
            } : isSmall ? {
                left: '-12%',
                top: '27%',
                borderRadius: '50%',
                backgroundColor: "#8DDCFF",
                width: '525px',
                height: "525px",
            } : {
                left: '-12%',
                top: '27%',
                borderRadius: '50%',
                backgroundColor: "#8DDCFF",
                width: '425px',
                height: "425px",
            }

    const leftTopImageEarthPositonCSS = isLarge
        ? {
            top: '-100px',
            left: '400px',
            width: '139px',
            height: '158px',
        } :
        isMedium2 ? {
            top: '-80px',
            left: '300px',
            width: '109px',
            height: '128px',
        }
            : isMedium1 ? {
                top: '-80px',
                left: '300px',
                width: '99px',
                height: '118px',
            } : isSmall ? {
                top: '-80px',
                left: '230px',
                width: '89px',
                height: '108px',
            } : {
                top: '-70px',
                left: '180px',
                width: '79px',
                height: "98px"
            }

    const leftDownImageEarthPositonCSS = isLarge
        ? {
            top: '-520px',
            left: '290px',
            width: '139px',
            height: '158px',
            borderRadius: '35px'
        } :
        isMedium2 ? {
            top: '-412px',
            left: '230px',
            width: '109px',
            height: '128px',
            // borderRadius: '30px'
        }
            : isMedium1 ? {
                top: '-358px',
                left: '200px',
                width: '99px',
                height: '118px',
                borderRadius: '30px'
            } : isSmall ? {
                top: '-330px',
                left: '170px',
                width: '89px',
                height: '108px',
                borderRadius: '30px'
            } : {
                top: '-260px',
                left: '120px',
                width: '79px',
                height: "98px",
                borderRadius: '25px'
            }

    const rightTopImageEarthPositonCSS = isLarge
        ? {
            top: '250px',
            left: '330px',
            width: '139px',
            height: '158px',
        } :
        isMedium2 ? {
            top: '149px',
            left: '231px',
            width: '109px',
            height: '128px',
        }
            : isMedium1 ? {
                top: '140px',
                left: '200px',
                width: '99px',
                height: '118px',
            } : isSmall ? {
                top: '120px',
                left: '180px',
                width: '89px',
                height: '108px',
            } : {
                top: '80px',
                left: '140px',
                width: '79px',
                height: "98px"
            }

    const rightDownImageEarthPositonCSS = isLarge
        ? {
            top: '-162px',
            left: '100px',
            width: '139px',
            height: '158px',
        } :
        isMedium2 ? {
            top: '-170px',
            left: '71px',
            width: '109px',
            height: '128px',
        }
            : isMedium1 ? {
                top: '-128px',
                left: '50px',
                width: '99px',
                height: '118px',
            } : isSmall ? {
                top: '-120px',
                left: '45px',
                width: '89px',
                height: '108px',
            } : {
                top: '-100px',
                left: '40px',
                width: '79px',
                height: "98px"
            }


    const leftSVGDivCss = isLarge
        ? {
            left: '30%',
            top: '5%',
        } : isMedium2 ? {
            left: '29%',
            top: '2%',
        } : isMedium1 ? {
            left: '30%',
            top: '2%',
        } : isSmall ? {
            left: '30%',
            top: '2%',
        } : {
            left: '30%',
            top: '2%',
        }

    const rightSVGDivCss = isLarge
        ? {
            left: '57%',
            top: '40%',
        } : isMedium2 ? {
            left: '55%',
            top: '32%',
        } : isMedium1 ? {
            left: '53%',
            top: '35%',
        } : isSmall ? {
            left: '55%',
            top: '38%',
        } : {
            left: '55%',
            top: '37%',
        }


    return (
        <div className='earthMedia-parent-div' >

            <motion.div className="earthMedia-div" 

                animate={earthDivCss}

                transition={{
                    duration: dur,
                    delay: delay
                }}
            >
                <div className="col-1" >

                    <motion.div className="svg-div"
                        animate={leftSVGDivCss}
                        transition={{
                            duration: dur,
                            delay: delay,
                            ease: 'easeInOut',
                        }}
                    >
                        <motion.svg xmlns="http://www.w3.org/2000/svg" width="415.304" height="291.353" viewBox="0 0 415.304 291.353"
                            initial={{
                                opacity: 0,
                                scale: 0
                            }}
                            animate={{
                                opacity: 1,
                                scale: 1
                            }}
                            transition={{
                                duration: dur,
                                delay: delay
                            }}
                        >
                            <motion.path id="Path_1" data-name="Path 1" transform="translate(-2609.512 -531.701)" fill="#FBE9D7"
                                d="M2830.286,547.492c-105.359,46.784-140.76-66.238-203.546,51.266s64.632,130.346,86.287,208.728c11.672,42.247,46.48-14.352,117.259-19.136s130.228,36.793,165.856,0c77.285-79.811-22.471-2.08-55.909-101.77s92.886-42.045,78.387-79.737S2935.645,500.709,2830.286,547.492Z"
                            />
                        </motion.svg>
                    </motion.div>

                    <motion.div className='img-1-div-parent'
                        initial={{
                            height: '35%'
                        }}
                        animate={leftTopImageEarthPositonCSS}
                        transition={{
                            duration: dur,
                            delay: delay
                        }}
                    >
                        <motion.div className='img-11-div'>
                            <motion.img src={image1} alt=""
                                initial={{
                                    scale: 10,
                                }}
                                animate={{
                                    scale: 1,
                                }}
                                transition={{
                                    duration: dur,
                                    delay: delay
                                }}
                            />

                            <motion.p
                                initial={{
                                    opacity: 1
                                }}
                                animate={{
                                    opacity: 0
                                }}
                                transition={{
                                    duration: dur,
                                    delay: delay,
                                    ease: "easeInOut"
                                }}
                            >
                                All new way photo viewing experience
                            </motion.p>

                        </motion.div>

                        <motion.div className='img-12-div'
                            intitial={{ top: '0px' }}
                            animate={{ top: isLarge ? '25px' : isMedium2 ? '20px' : isMedium1 ? "19px" : isSmall ? "16px" : "14px" }}
                            transition={{
                                duration: dur,
                                delay: delay
                            }}
                        >
                            <motion.img className='img1' src={image1} alt=""
                            />
                        </motion.div>
                    </motion.div>


                    <motion.div className='img-2-div-parent'
                        initial={{
                            height: '63%',
                        }}
                        animate={leftDownImageEarthPositonCSS}
                        transition={{
                            duration: dur,
                            delay: delay
                        }}
                    >
                        <motion.div className='img-21-div'
                            animate={{ borderRadius: isLarge ? "35px" : isSmall ? "30px" : "25px" }}
                            transition={{
                                duration: dur,
                                delay: delay
                            }}
                        >
                            <motion.img src={image2} alt=""
                                animate={{ borderRadius: isLarge ? "35px" : isSmall ? "30px" : "25px" }}
                                transition={{
                                    duration: dur,
                                    delay: delay
                                }}
                            />
                        </motion.div>

                        <motion.div className='img-22-div'
                            initial={{ top: 0 }}
                            animate={{ top: isLarge ? '24px' : isMedium2 ? '20px' : isMedium1 ? "18px" : isSmall ? "15px" : "13px" }}
                            transition={{
                                duration: dur,
                                delay
                            }}
                        >
                            <motion.img src={image2} alt=""
                            />
                        </motion.div>
                    </motion.div>
                </div>

                <div className="col-2">
                    <motion.div className="svg-div"
                        initial={{
                            top: '50%',
                            left: '0%',
                        }}
                        animate={rightSVGDivCss}
                        transition={{
                            duration: dur,
                            delay: delay,
                            ease: 'easeInOut',
                        }}
                    >
                        <motion.svg xmlns="http://www.w3.org/2000/svg" width="415.304" height="291.353" viewBox="0 0 415.304 291.353"
                            initial={{
                                opacity: 0,
                                scale: 0
                            }}
                            animate={{
                                opacity: 1,
                                scale: 1
                            }}

                            transition={{
                                duration: dur,
                                delay: delay
                            }}
                        >
                            <motion.path id="Path_1" data-name="Path 1" d="M2830.286,547.492c-50.359,46.784-140.76-66.238-203.546,51.266s64.632,10.346,86.287,208.328c11.672,42.247,46.48-14.352,117.259-19.126s130.228,36.793,165.956,0c77.285-79.811-22.471-2.08-55.909-101.77s92.886-42.045,78.387-79.737S2935.645,600.709,2830.286,547.492Z" transform="translate(-2609.512 -531.701)" fill="#FBE9D7" />
                        </motion.svg>
                    </motion.div>

                    <motion.div className='img-1-div-parent'
                        initial={{
                            height: '63%'
                        }}

                        animate={rightTopImageEarthPositonCSS}

                        transition={{
                            duration: dur,
                            delay: delay
                        }}>

                        <motion.div className='img-11-div'
                        >
                            <motion.img src={image3} alt=""
                            />
                        </motion.div>

                        <motion.div className='img-12-div'
                            intitial={{ top: 0 }}
                            animate={{ top: isLarge ? '25px' : isMedium2 ? '20px' : isMedium1 ? "19px" : isSmall ? "16px" : "14px" }}
                            transition={{
                                duration: dur,
                                delay: delay
                            }}
                        >
                            <motion.img src={image3} alt=""
                            />
                        </motion.div>
                    </motion.div>


                    <motion.div className='img-2-div-parent'
                        initial={{
                            height: '35%'
                        }}

                        animate={rightDownImageEarthPositonCSS}

                        transition={{
                            duration: dur,
                            delay: delay
                        }}
                    >
                        <motion.div className='img-21-div'
                        >
                            <motion.img src={image4} alt=""
                            />
                        </motion.div>
                        <motion.div className='img-22-div'
                            intitial={{ top: 0 }}
                            animate={{ top: isLarge ? '25px' : isMedium2 ? '20px' : isMedium1 ? "19px" : isSmall ? "16px" : "14px" }}
                            transition={{
                                duration: dur,
                                delay: delay
                            }}
                        >
                            <motion.img src={image4} alt=""
                            />
                        </motion.div>
                    </motion.div>

                </div>

            </motion.div>

            <motion.div className='earthMedia-content-div'
                initial={{
                    left: '100%',
                    opacity: 0,
                }}
                animate={{
                    left: isLarge ? '50%' : isMedium2 ? "50%" : isMedium1 ? "52%" : isSmall ? "59%" : "55% ",
                    opacity: 1,
                }}
                transition={{
                    duration: dur,
                    delay: delay
                }}
            >
                View interesting posts from people across the globe. Share your comments. You can also create unique posts to feature on the global feed. An awesome way to share your thoughts. Make it more interactive to attract followers and vibers. Chill with new people. Expand your circle.
            </motion.div>
        </div >
    )
}



export default EarthMedia;