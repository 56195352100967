import "./index.css";
import playStore from "../../../logosAndImages/Frame 272.svg";
import appStore from "../../../logosAndImages/Frame 273.svg";
import phone from "../../../logosAndImages/iPhone 14 Pro.png";

const Download = () => {
  const num = [1, 2, 3, 4, 5];
  return (
    <>
      <div className="downloadPage">
        <div className="mainContent">
          <div className="appLink">
            <div className="linkText">
              <h2>Vibe</h2>
              <h2>with new</h2>
              <h2>people today</h2>
              <p>Highon is currently available on iOS and Android devices.</p>
            </div>
            <div className="storeLogo">
              <div className="playStore">
                <a href="https://play.google.com/store/apps/details?id=com.highOn.highOn" target='_blank'>
                <img src={playStore} alt="playstoreimage" />
                </a>
                
              </div>
              <div className="appStore">
              <a href="https://play.google.com/store/apps/details?id=com.highOn.highOn" target='_blank'>
                <img src={appStore} alt="appstoreimage" />
              </a>
              </div>
            </div>
          </div>
          <div className="iphone">
            <div>
              <img src={phone} alt="phoneimage" />
            </div>
          </div>
          <div className="phoneZigZag">
            {num.map((item, index) => {
              return (
                <div className={"mini" + index}>
                  <img src={phone} alt="phoneimage" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="gap"></div>
      </div>
    </>
  );
};

export default Download;
