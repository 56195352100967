import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'

import './earthDiv.styles.scss'

import image1 from '../../assets/4.png';
import image2 from '../../assets/1.png';
import image3 from '../../assets/2.png';
import image4 from '../../assets/3.png';

const EarthDiv = () => {

    const delay = 2
    const dur = 1

    const useMediaQuery = (query) => {
        const [matches, setMatches] = useState(false);

        useEffect(() => {
            const media = window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }
            const listener = () => {
                setMatches(media.matches);
            };
            media.addListener(listener);
            return () => media.removeListener(listener);
        }, [matches, query]);

        return matches;
    }

    const useIsLarge = () => useMediaQuery('(min-width: 1700px)');
    const useIsMedium2 = () => useMediaQuery('(min-width: 1300px)');
    const useIsMedium1 = () => useMediaQuery('(min-width: 1000px)');
    const useIsSmall = () => useMediaQuery('(min-width: 700px)');

    const isLarge = useIsLarge()
    const isMedium2 = useIsMedium2()
    const isMedium1 = useIsMedium1()
    const isSmall = useIsSmall()

    const earthDivCss = isLarge
        ? {
            height: '720px',
            width: '720px',
            left: '32%',
            top: '17%',
        } : isMedium2 ? {
            height: '570px',
            width: '570px',
            left: '30%',
            top: '9.4%',
        } : isMedium1 ? {
            height: '450px',
            width: '450px',
            left: '32%',
            top: '25%',
        } : isSmall ? {
            height: '400px',
            width: '400px',
            left: '30%',
            top: '20%',
        } : {
            height: '350px',
            width: '350px',
            left: '25%',
            top: '15%',
        }

    return (
        <div className='earth-parent-div'>

            <motion.div className="earth-div"

                animate={earthDivCss}

                transition={{
                    duration: dur,
                    delay: delay
                }}
            >

                <div className="col-1" >
                    <motion.div className='svg-div'>
                        <motion.svg xmlns="http://www.w3.org/2000/svg" width="415.304" height="291.353" viewBox="0 0 415.304 291.353"
                            initial={{
                                opacity: 1,
                                scale: 1,
                            }}
                            animate={{
                                // left: '70px',
                                // top: '80px'
                            }}

                            transition={{
                                duration: dur,
                                delay: delay
                            }}
                        >
                            <motion.path id="Path_1" data-name="Path 1" transform="translate(-2609.512 -531.701)" fill="#FBE9D7"

                                initial={{
                                    d: "M2830.286,547.492c-105.359,46.784-140.76-66.238-203.546,51.266s64.632,130.346,86.287,208.728c11.672,42.247,46.48-14.352,117.259-19.136s130.228,36.793,165.856,0c77.285-79.811-22.471-2.08-55.909-101.77s92.886-42.045,78.387-79.737S2935.645,500.709,2830.286,547.492Z"
                                }}

                                animate={{
                                    d: "M2830.286,547.492c-50.359,46.784-140.76-66.238-203.546,51.266s64.632,10.346,86.287,208.328c11.672,42.247,46.48-14.352,117.259-19.126s130.228,36.793,165.956,0c77.285-79.811-22.471-2.08-55.909-101.77s92.886-42.045,78.387-79.737S2935.645,600.709,2830.286,547.492Z"
                                }}

                                transition={{
                                    duration: dur,
                                    delay: delay
                                }}
                            />
                        </motion.svg>
                    </motion.div>

                    <motion.div className='img-1-div-parent'
                        initial={{
                        }}
                        animate={{
                            scale: 0,
                            top: '500px',
                            left: '-1300px',
                            opacity: 0
                        }}
                        transition={{
                            duration: dur,
                            delay: delay
                        }}
                    >
                        <motion.div className='img-11-div'>

                            <motion.img src={image1} alt=""
                            />
                        </motion.div>

                        <motion.div className='img-12-div'>
                            <motion.img src={image1} alt="" />
                        </motion.div>
                    </motion.div>

                    <motion.div className='img-2-div-parent'
                        initial={{
                        }}
                        animate={{
                            scale: 0,
                            top: '500px',
                            left: '-1300px',
                            opacity: 0
                        }}
                        transition={{
                            duration: dur,
                            delay: delay
                        }}
                    >
                        <motion.div className='img-21-div'
                        >
                            <motion.img src={image2} alt=""
                            />
                        </motion.div>
                        <motion.div className='img-22-div'
                        >
                            <motion.img src={image2} alt=""
                            />
                        </motion.div>
                    </motion.div>
                </div>

                <div className="col-2">

                    <motion.div className='svg-div'>
                        <motion.svg xmlns="http://www.w3.org/2000/svg" width="415.304" height="291.353" viewBox="0 0 415.304 291.353"
                            initial={{
                                opacity: 1,
                                scale: 1,
                            }}
                            animate={{
                                // left: '130px'
                            }}

                            transition={{
                                duration: dur,
                                delay: delay
                            }}
                        >
                            <motion.path id="Path_1" data-name="Path 1" transform="translate(-2609.512 -531.701)" fill="#FBE9D7"

                                initial={{
                                    d: "M2830.286,547.492c-50.359,46.784-140.76-66.238-203.546,51.266s64.632,10.346,86.287,208.328c11.672,42.247,46.48-14.352,117.259-19.126s130.228,36.793,165.956,0c77.285-79.811-22.471-2.08-55.909-101.77s92.886-42.045,78.387-79.737S2935.645,600.709,2830.286,547.492Z"
                                }}

                                animate={{
                                    d: "M2830.286,547.492c-105.359,46.784-140.76-66.238-203.546,51.266s64.632,130.346,86.287,208.728c11.672,42.247,46.48-14.352,117.259-19.136s130.228,36.793,165.856,0c77.285-79.811-22.471-2.08-55.909-101.77s92.886-42.045,-8.387-79.737S2935.645,500.709,2830.286,547.492Z"
                                }}

                                transition={{
                                    duration: dur,
                                    delay: delay
                                }}

                            />
                        </motion.svg>

                    </motion.div>

                    <motion.div className='img-1-div-parent'
                        initial={{
                        }}

                        animate={{
                            scale: 0,
                            top: '500px',
                            left: '-1300px',
                            opacity: 0
                        }}

                        transition={{
                            duration: dur,
                            delay: delay
                        }}
                    >
                        <motion.div className='img-11-div'
                        >
                            <motion.img src={image3} alt=""
                            />
                        </motion.div>
                        <motion.div className='img-12-div'
                        >
                            <motion.img src={image3} alt=""
                            />
                        </motion.div>
                    </motion.div>

                    <motion.div className='img-2-div-parent'

                        initial={{
                        }}

                        animate={{
                            scale: 0,
                            top: '500px',
                            left: '-1300px',
                            opacity: 0
                        }}

                        transition={{
                            duration: dur,
                            delay: delay
                        }}>
                        <motion.div className='img-21-div'
                        >
                            <motion.img src={image4} alt=""
                            />
                        </motion.div>
                        <motion.div className='img-22-div'
                        >
                            <motion.img src={image4} alt=""
                            />
                        </motion.div>
                    </motion.div>
                </div>

            </motion.div >


            <motion.div className='earth-content-div'
                initial={{
                }}
                animate={{
                    left: '100%',
                    top: isLarge ? '-30%' : isMedium2 ? '-70%' : isMedium1 ? "-20%" : isSmall ? "-60%" : "-60%",
                    opacity: 0,
                }}
                transition={{
                    duration: dur,
                    delay: delay
                }}
            >
                View interesting posts from people across the globe. Share your comments. You can also create unique posts to feature on the global feed. An awesome way to share your thoughts. Make it more interactive to attract followers and vibers. Chill with new people. Expand your circle.
            </motion.div>

        </div >

    )
}

export default EarthDiv;