import React, { useRef, useState, useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic";
import Sequence from "./Sequence";
import "./TextSequencer.css";

const TextSequencer = () => {
  const ref = useRef();

  const [mobileScreenView, setMobileScreenView] = useState(true);

  useEffect(() => {
    if (window.innerWidth > 600) {
      setMobileScreenView(false);
    }
  }, []);

  return (
    <div className="knowUnknownWrapper">
      <div
        className="imageSequencer5Vibe"
        style={{ width: "80vw", marginLeft: "5vw" }}
      >
        <Controller>
          <Scene duration="30%" triggerHook="onCenter">
            {(progress) => (
              <div
                className="imageSequenceContainer"
                style={{ height: "100vh", position: "relative" }}
              >
                <Sequence ref={ref} progress={progress} />
              </div>
            )}
          </Scene>
        </Controller>
      </div>
      <div className="knowUnknownSection">
        <h1>Know the Unknown</h1>
        <span style={{ textAlign: "justify" }}>
          <b className="highlightText"> Socializing </b>has never been easier.
          <br />
          Meet people who truly appreciate you. <br />
          Discover <b className="highlightText">funky avatars</b>.<br /> Let
          your heart out. <br />
          Build a reliable tribe to vibe with.
          <br />
        </span>
        <br />{" "}
        <span>
          <b>We find your tribe so that you can Vibe.</b>
        </span>
      </div>
    </div>
  );
};

export default TextSequencer;
