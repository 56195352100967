import React from "react";
import AllMobileScreens from "./components/AllMobileScreens";
import AnimatedCorousel from "./components/Carousel";
import TopSection from "./components/TopSection";
import ZinkuSection from "./components/ZinkuSection";
import BadgesSection from "./components/badges/badgeSection.component";
// import Vibe from "../../../vibe/vibe.component";
import TextSequencer from "./TextSequencer";
import VibeWithUsSequencer from "./VibeWithUsSequencer";

const HomePageNew = () => {
  return (
    <>
      <TopSection />
      <TextSequencer />
      <AnimatedCorousel />
      <BadgesSection />
      <ZinkuSection />
      <VibeWithUsSequencer />
      <AllMobileScreens />
    </>
  );
};

export default HomePageNew;
